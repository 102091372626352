import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BlogPostTags from '../components/blog-post-tags'

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || 'Title'
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title='All posts' />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title='All posts' />
      {/* <StaticImage
        className="home-header"
        layout="fullWidth"
        formats={["AUTO", "WEBP", "JPEG", "PNG", "AVIF"]}
        src="../images/home-header.png"
        quality={95}
        alt="Home header"
      /> */}
      <ol className='post-list'>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const image = getImage(post.frontmatter.socialImage) || null
          return (
            <li className='post-list-item' key={post.fields.slug}>
              <article
                // className="post-list-item"
                itemScope
                itemType='http://schema.org/Article'
              >
                <header>
                  <Link to={post.fields.slug} itemProp='url'>
                    <GatsbyImage image={image} alt='thumbnail' />
                    <p>
                      <span itemProp='headline'>{title}</span>
                    </p>
                  </Link>
                  <div>
                    <small>{post.frontmatter.date}</small>
                  </div>
                  <BlogPostTags tags={post.frontmatter.tags} />
                </header>
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {frontmatter: {tags: {nin: "diary"}}}
      ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY.MM.DD")
          title
          description
          tags
          socialImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
